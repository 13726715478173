<template>
    <div class="vx-col w-full">
        <div class="h-12" style="background-color: #dae1e7;border-radius: 5px;padding: 10px;">
          <h4>Caisse</h4>
        </div>
        <div class="vx-row p-5">
          <div class="vx-col w-full">
            <div class="flex space-between">
              <div class="flex mr-5">
                <h5>
                  Alerte initialisation & clôture de caisse
                </h5>
                <vx-tooltip
                  title="Infos caisse"
                  text="Une alerte est envoyé à la liste de mail configuré si la caisse de l' officine n' est pas initialiser ou clôturer après l' heure spécifié"
                >
                  <feather-icon class="ml-1" icon="InfoIcon" />
                </vx-tooltip>
              </div>

              <vs-switch v-model="alerteInitCaisse">
                <span slot="on">Oui</span>
                <span slot="off">Non</span>
              </vs-switch>
            </div>
            <div class="vx-col w-full mt-5" v-if="alerteInitCaisse">
              <div class="vx-row">
                <div class="vx-col pt-2">
                    Heure initialisation:
                </div>
                <div class="vx-col">
                  <flat-pickr :config="configdateTimePicker" v-model="alertInitCaisseTime" placeholder="Choisissez une heure d'alerte" />
                </div>
                <div class="vx-col pt-2">
                  Heure clôture:
                </div>
                <div class="vx-col">
                  <flat-pickr :config="configdateTimePicker" v-model="alertClotureCaisseTime" placeholder="Choisissez une heure d'alerte" />
                </div>
                <div class="vx-col pt-2" v-if="selectUserOfficine(activeUserInfos.IdOfficine) && selectUserOfficine(activeUserInfos.IdOfficine).OfficinePrincipale === true">
                  Agence:
                </div>
                <div class="vx-col" v-if="selectUserOfficine(activeUserInfos.IdOfficine) && selectUserOfficine(activeUserInfos.IdOfficine).OfficinePrincipale === true">
                  <vs-select multiple autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="Officines" @change="CurrentOfficineSelectClient()">
                    <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
                  </vs-select>
                </div>
              </div>
              <vs-textarea class="mt-5" label="Emails" v-model="alertinitCaisseEmailListe" />
              <feather-icon icon="AlertTriangleIcon" />
              <p class="font-black">
                Les emails saisis doivent être séparés par des ;. Example: test1@domain.com;test2@domain.com
              </p>
            </div>
          </div>
        </div>
        <vs-row v-if="alerteInitCaisse" class="pl-6 pr-10" vs-type="flex" vs-justify="flex-end">
          <vs-col vs-type="flex" vs-justify="end" vs-align="end">
            <vs-button :disabled="!validAlertinitCaisseForm" class="mr-2" @click="addCaisseAlert">Sauvegarder</vs-button>
            <vs-button :disabled="!validAlertinitCaisseForm" color="danger" type="border" @click="getCaisseAlertInfo">Annuler</vs-button>
          </vs-col>
        </vs-row>
    </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import {French as FrenchLocale} from 'flatpickr/dist/l10n/fr.js'
export default {
  name: 'Caisse',
  components: {
    flatPickr
  },
  data () {
    return {
      activeUserInfos: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
      IdUser: JSON.parse(localStorage.getItem('userInfo'))._id,
      IdOfficine: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : {},
      Officines: [localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : {}],
      /* caisse */
      alertCaisseId: null,
      alerteInitCaisse: false,
      alertInitCaisseTime: null,
      alertClotureCaisseTime: null,
      alertinitCaisseEmailListe: '',

      configdateTimePicker: {
        locale: FrenchLocale,
        enableTime: true,
        enableSeconds: true,
        noCalendar: true
      }
    }
  },
  computed: {
    validAlertinitCaisseForm () {
      return (this.alertInitCaisseTime !== null || this.alertClotureCaisseTime !== null) &&  this.alertinitCaisseEmailListe !== ''
    },
    officines () {
      return this.$store.state.officine.officines
    }
  },
  methods: {
    addCaisseAlert () {

      if (!this.alertCaisseId) {
        const payload = {
          IdOfficine: this.IdOfficine,
          IdUser: this.IdUser,
          alerteInitCaisse: this.alerteInitCaisse,
          alertInitCaisseTime: this.alertInitCaisseTime,
          alertClotureCaisseTime: this.alertClotureCaisseTime,
          alertinitCaisseEmailListe: this.alertinitCaisseEmailListe,
          Officines: this.Officines
        }

        this.$vs.loading({
          type: 'sound'
        })

        this.$store.dispatch('configs/addCaisseAlert', payload)
          .then((data) => {
            this.$vs.notify({
              title: 'success',
              text: 'success',
              iconPack: 'feather',
              icon: 'icon-check',
              color: 'primary'
            })

            this.alertCaisseId = data._id
            this.$vs.loading.close()
          })
          .catch(err => {
            console.error(err)
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'error',
              text: `${err}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
          })
      } else {
        const payload = {
          IdOfficine: this.IdOfficine,
          IdUser: this.IdUser,
          alerteInitCaisse: this.alerteInitCaisse,
          alertInitCaisseTime: this.alertInitCaisseTime,
          alertClotureCaisseTime: this.alertClotureCaisseTime,
          alertinitCaisseEmailListe: this.alertinitCaisseEmailListe,
          id: this.alertCaisseId,
          Officines: this.Officines
        }

        this.$vs.loading({
          type: 'sound'
        })

        this.$store.dispatch('configs/updateCaisseAlert', payload)
          .then((data) => {
            this.$vs.notify({
              title: 'success',
              text: 'success',
              iconPack: 'feather',
              icon: 'icon-check',
              color: 'primary'
            })

            this.alertCaisseId = data._id
            this.$vs.loading.close()
          })
          .catch(err => {
            console.error(err)
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'error',
              text: `${err}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
          })
      }

    },
    getCaisseAlertInfo () {
      const payload = {
        id: this.IdOfficine
      }
      this.$store.dispatch('configs/getCaisseAlertInfo', payload)
        .then((response) => {
          this.alertCaisseId = response.data ? response.data._id : null
          this.alerteInitCaisse = response.data ? response.data.alerteInitCaisse : false
          this.alertInitCaisseTime = response.data ? response.data.alertInitCaisseTime : null
          this.alertClotureCaisseTime = response.data ? response.data.alertClotureCaisseTime : null
          this.alertinitCaisseEmailListe = response.data ? response.data.alertinitCaisseEmailListe : ''
          this.Officines = response.data ? response.data.Officines : [localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : {}]
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    selectUserOfficine (id) {
      return this.$store.getters['officine/getOfficineById'](id)
    }
  },
  created () {
    this.getCaisseAlertInfo()
  }
}
</script>