<template>
    <div class="vx-col w-full mt-5">
        <vs-prompt
          class="calendar-event-dialog"
          title="Ajouter un férié"
          accept-text= "Ajouter un férié"
          @accept="addFerie"
          :is-valid="validForm"
          :active.sync="activePromptAddFerie">

          <vs-input name="event-name" v-validate="'required'" class="w-full" label-placeholder="Férié" v-model="libelle"></vs-input>
          <div class="my-4">
            <small class="date-label">Date</small>
            <datepicker :language="$vs.rtl ? langHe : langFr" name="dateFerie" v-model="dateFerie"></datepicker>
          </div>

        </vs-prompt>
        <vs-prompt
          class="calendar-event-dialog"
          title="Modifier un férié"
          accept-text= "Modifier"
          @accept="updateFerie"
          :is-valid="validUpdateForm"
          :active.sync="activePromptUpdateFerie">

          <vs-input name="event-name" v-validate="'required'" class="w-full" label-placeholder="Férié" v-model="libelleUpdate"></vs-input>
          <div class="my-4">
            <small class="date-label">Date</small>
            <datepicker :language="$vs.rtl ? langHe : langFr" name="dateFerie" v-model="dateFerieUpdate"></datepicker>
          </div>

        </vs-prompt>
        <div class="h-12" style="background-color: #dae1e7;border-radius: 5px;padding: 10px;">
          <h4>Jours fériés</h4>
        </div>
        <div class="vx-row p-5">
          <div class="vx-col mt-8">
            <span>Année:</span>
          </div>
          <div class="vx-col mt-5">
            <datepicker :language="$vs.rtl ? langHe : langFr" :minimumView="'month'" v-model="annee"></datepicker>
          </div>
          <div class="vx-col mt-5">
            <vs-button icon-pack="feather" icon="icon-search" @click="searchFerieManuel"></vs-button>
          </div>
          <div class="vx-col mt-5">
            <vs-button v-if="selectUserOfficine(activeUserInfos.IdOfficine) && selectUserOfficine(activeUserInfos.IdOfficine).OfficinePrincipale === true" icon-pack="feather" icon="icon-calendar" @click="activePromptAddFerie=true">Ajouter un férié</vs-button>
          </div>
          <div class="vx-col w-full mt-5">
            <vs-table stripe noDataText="aucune donnée" max-items="5" pagination :data="ferierData">
              <template slot="thead">
                <vs-th>Jours fériés</vs-th>
                <vs-th>Date</vs-th>
                <vs-th v-if="selectUserOfficine(activeUserInfos.IdOfficine) && selectUserOfficine(activeUserInfos.IdOfficine).OfficinePrincipale === true">Actions</vs-th>
              </template>

              <template v-if="ferierData.length>0" slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                  <vs-td :data="tr.libelle">
                    {{ tr.libelle }}
                  </vs-td>

                  <vs-td>
                    {{ tr.dateFerie | moment("calendar", "July 10 2011") }}
                  </vs-td>

                  <vs-td v-if="selectUserOfficine(activeUserInfos.IdOfficine) && selectUserOfficine(activeUserInfos.IdOfficine).OfficinePrincipale === true">
                    <div class="dropdown-button-container">
                      <vs-dropdown>
                        <vs-button class="btn-drop" icon="more_vert"></vs-button>
                        <vs-dropdown-menu>

                          <vs-dropdown-item @click="showUpdateFerieForm(tr)"> Modifier </vs-dropdown-item>
                          <vs-dropdown-item @click="confirmDeleteFerie(tr)"> Supprimer </vs-dropdown-item>
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </div>
                  </vs-td>

                </vs-tr>
              </template>

            </vs-table>
          </div>
        </div>
    </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker'
import { en, he, fr } from 'vuejs-datepicker/src/locale'
export default {
  name: 'Ferier',
  components: {
    Datepicker
  },
  data () {
    return {
      activeUserInfos: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
      IdUser: JSON.parse(localStorage.getItem('userInfo'))._id,
      annee: new Date(new Date().getFullYear(), 0, 1),
      libelle: '',
      dateFerie: null,
      activePromptAddFerie: false,

      activePromptUpdateFerie: false,

      anneeUpdate: null,
      libelleUpdate: '',
      dateFerieUpdate: null,
      idFerie: null,
      langHe: he,
      langEn: en,
      langFr: fr
    }
  },
  computed: {
    validForm () {
      return this.libelle !== '' && this.dateFerie !== null
    },
    validUpdateForm () {
      return this.libelleUpdate !== '' && this.dateFerieUpdate !== null
    },
    ferierData () {
      return this.$store.state.configs.feries
    },
    officines () {
      return this.$store.state.officine.officines
    }
  },
  methods: {
    addFerie () {
      const payload = {
        IdUser: this.IdUser,
        annee: this.annee.getFullYear(),
        libelle: this.libelle,
        dateFerie: new Date(new Date(this.dateFerie)).toISOString().split('T')[0]
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('configs/addFerie', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.libelle = ''
          this.dateFerie = null

          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    updateFerie () {
      const payload = {
        IdUser: this.IdUser,
        annee: this.anneeUpdate,
        libelle: this.libelleUpdate,
        dateFerie: new Date(new Date(this.dateFerieUpdate)).toISOString().split('T')[0],
        id: this.idFerie
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('configs/updateFerie', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.activePromptUpdateFerie = false
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    confirmDeleteFerie (data) {
      this.idFerie = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Alerte Suppression',
        text: `Vous allez supprimer "${data.libelle}"`,
        acceptText: 'Supprimer',
        accept: this.deleteFerie
      })
    },
    deleteFerie () {
      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('configs/deleteFerie', this.idFerie)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    searchFerieManuel () {
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('configs/getFeriesByYear', this.annee.getFullYear())
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getFeriesByYear () {
      const annee = new Date(new Date().getFullYear(), 0, 1)
      this.$store.dispatch('configs/getFeriesByYear', annee.getFullYear())
        .then(() => {

        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    showUpdateFerieForm (data) {
      this.idFerie = data._id
      this.anneeUpdate = data.annee
      this.libelleUpdate = data.libelle
      this.dateFerieUpdate = data.dateFerie
      this.activePromptUpdateFerie = true
    },
    selectUserOfficine (id) {
      return this.$store.getters['officine/getOfficineById'](id)
    }
  },
  created () {
    this.getFeriesByYear()
  }
}
</script>