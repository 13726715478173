<template>
  <vx-card>
    <div class="vx-row">
      <!-- Garantie -->
      <Garantie></Garantie>
      <!-- /Garantie -->
      <!-- template sms -->
      <templateSMS></templateSMS>
      <!-- /template sms -->
      <!-- caisse -->
      <Caisse></Caisse>
      <!-- /caisse -->
      <!-- ferier -->
      <Ferier></Ferier>
      <!-- /ferier -->
      <!-- api sms -->
      <ConfigSMS></ConfigSMS>
      <!-- api sms -->
    </div>
  </vx-card>
</template>
<script>
import Garantie from './components/Garantie.vue'
import templateSMS from './components/templateSMS.vue'
import ConfigSMS from './components/ConfigSMS.vue'
import Caisse from './components/Caisse.vue'
import Ferier from './components/Ferier.vue'
export default {
  components: {
    Garantie,
    templateSMS,
    ConfigSMS,
    Caisse,
    Ferier
  },
  data () {
    return {
      
    }
  },
  methods: {
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    }
  },
  created () {
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
  }
}
</script>
