<template>
    <div class="vx-col w-full">
        <div class="h-12" style="background-color: #dae1e7;border-radius: 5px;padding: 10px;">
          <h4>API SMS</h4>
        </div>
        <div class="vx-row p-5">
          <div class="vx-col w-1/2">
            <vs-input name="senderName" class="w-full" label="Sender Name" v-model="senderName"></vs-input>
          </div>
          <div class="vx-col w-1/2">
            <vs-input name="senderAddress" class="w-full" label="Sender Address" v-model="senderAddress"></vs-input>
          </div>
        </div>
        <div class="vx-row p-5">
          <div class="vx-col w-1/2">
            <vs-input name="application_id_orange" class="w-full" label="Application ID" v-model="application_id_orange"></vs-input>
          </div>
          <div class="vx-col w-1/2">
            <vs-input name="client_id_orange" class="w-full" label="Client ID" v-model="client_id_orange"></vs-input>
          </div>
        </div>
        <div class="vx-row p-5">
          <div class="vx-col w-1/2">
            <vs-input name="client_secret_orange" class="w-full" label="Client secret" v-model="client_secret_orange"></vs-input>
          </div>
          <div class="vx-col w-1/2">
            <vs-input name="authorization_header_orange" class="w-full" label="Authorization header" v-model="authorization_header_orange"></vs-input>
          </div>
        </div>
        <vs-row class="p-5" vs-type="flex" vs-justify="flex-end">
          <vs-col vs-type="flex" vs-justify="end" vs-align="end">
            <vs-button @click="setSmsConfig">Sauvegarder</vs-button>
          </vs-col>
        </vs-row>
      </div>
</template>
<script>
export default {
  name: 'ConfigSMS',
  data () {
    return {
      IdUser: JSON.parse(localStorage.getItem('userInfo'))._id,
      id_sms_config: this.config ? this.config._id : null,
      senderName: this.config ? this.config.senderName : null,
      senderAddress: this.config ? this.config.senderAddress : null,
      application_id_default: this.config ? this.config.application_id_default : null,
      application_id_orange: this.config ? this.config.application_id_orange : null,
      client_id_orange: this.config ? this.config.client_id_orange : null,
      client_id_default: this.config ? this.config.client_id_default : null,
      client_secret_orange: this.config ? this.config.client_secret_orange : null,
      client_secret_default: this.config ? this.config.client_secret_default : null,
      authorization_header_orange: this.config ? this.config.authorization_header_orange : null,
      authorization_header_default: this.config ? this.config.authorization_header_default : null,
      token_type_orange: this.config ? this.config.token_type_orange : null,
      token_type_default: this.config ? this.config.token_type_default : null,
      access_token_orange: this.config ? this.config.access_token_orange : null,
      access_token_default: this.config ? this.config.access_token_default : null,
      expires_in_orange: this.config ? this.config.expires_in_orange : null,
      expires_in_default: this.config ? this.config.expires_in_default : null
    }
  },
  methods: {
    setSmsConfig () {
      const payload = {
        id_user: this.IdUser,
        senderName: this.senderName,
        senderAddress: this.senderAddress,
        application_id_default: this.application_id_default,
        application_id_orange: this.application_id_orange,
        client_id_orange: this.client_id_orange,
        client_id_default: this.client_id_default,
        client_secret_orange: this.client_secret_orange,
        client_secret_default: this.client_secret_default,
        authorization_header_orange: this.authorization_header_orange,
        authorization_header_default: this.authorization_header_default,
        token_type_orange: this.token_type_orange,
        token_type_default: this.token_type_default,
        access_token_orange: this.access_token_orange,
        access_token_default: this.access_token_default,
        expires_in_orange: this.expires_in_orange,
        expires_in_default: this.expires_in_default
      }
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('configs/setSmsConfig', payload)
        .then((data) => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.alertCaisseId = data._id
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getSmsConfig () {
      this.$store.dispatch('configs/getSmsConfig')
        .then((response) => {
          this.id_sms_config = response.data.smsConfig ? response.data.smsConfig._id : null
          this.senderName = response.data.smsConfig ? response.data.smsConfig.senderName : null
          this.senderAddress = response.data.smsConfig ? response.data.smsConfig.senderAddress : null
          this.application_id_default = response.data.smsConfig ? response.data.smsConfig.application_id_default : null
          this.application_id_orange = response.data.smsConfig ? response.data.smsConfig.application_id_orange : null
          this.client_id_orange = response.data.smsConfig ? response.data.smsConfig.client_id_orange : null
          this.client_id_default = response.data.smsConfig ? response.data.smsConfig.client_id_default : null
          this.client_secret_orange = response.data.smsConfig ? response.data.smsConfig.client_secret_orange : null
          this.client_secret_default = response.data.smsConfig ? response.data.smsConfig.client_secret_default : null
          this.authorization_header_orange = response.data.smsConfig ? response.data.smsConfig.authorization_header_orange : null
          this.authorization_header_default = response.data.smsConfig ? response.data.smsConfig.authorization_header_default : null
          this.token_type_orange = response.data.smsConfig ? response.data.smsConfig.token_type_orange : null
          this.token_type_default = response.data.smsConfig ? response.data.smsConfig.token_type_default : null
          this.access_token_orange = response.data.smsConfig ? response.data.smsConfig.access_token_orange : null
          this.access_token_default = response.data.smsConfig ? response.data.smsConfig.access_token_default : null
          this.expires_in_orange = response.data.smsConfig ? response.data.smsConfig.expires_in_orange : null
          this.expires_in_default = response.data.smsConfig ? response.data.smsConfig.expires_in_default : null
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    }
  },
  created () {
    this.getSmsConfig()
  }
}
</script>