<template>
    <div class="vx-col w-full mt-5">
        <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
          <div class="h-full">
            <div class="customizer-header mt-6 flex items-center justify-between px-6">
              <div>
                <h4>Ajouter un nouveau type de message</h4>
                <!--<small>Customize & Preview in Real Time</small>-->
              </div>
              <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
            </div>

            <vs-divider class="mb-0" />

            <component :is="scrollbarTag" class="scroll-area--customizer pt-4 pb-6" :settings="settings" :key="$vs.rtl">
              <div class="px-6 py-10">
                <form class="space-y-5" @submit.prevent="addTypeMessage()">
                  <!-- <vs-input class="w-full" label="Type de message" v-model="type_name" /> -->
                  <!-- <vx-tooltip
                      color="#000000"
                      text="Vous pouvez faire figurer le nom ou les prénoms du client en utilisant les crochets, Exemple: Hello [Nom] [Prenom] -> Hello Koffi Jean ou Nom = Koffi Prenom = Jean;"
                      >
                      <feather-icon class="ml-1" icon="InfoIcon" />
                  </vx-tooltip> -->
                  <div class="flex justify-between">
                    <h5>Automatique</h5>
                    <vs-switch v-model="auto" />
                  </div>
                  <vs-select label="Objet du Message" autocomplete class="w-full" v-model="objet">
                    <vs-select-item  class="w-full" :key="index" :value="item.value" :text="item.text" v-for="(item,index) in emum" />
                  </vs-select>
                  <vs-textarea label="Message" v-model="content" />
                  <div class="flex justify-end mt-5">
                    <vs-button class="mr-2" @click="addTypeMessage">Sauvegarder</vs-button>
                    <vs-button color="danger" type="border" @click="isSidebarActiveLocal = false">Annuler</vs-button>
                  </div>
                </form>
              </div>
            </component>
          </div>
        </vs-sidebar>
        <div class="h-12" style="background-color: #dae1e7;border-radius: 5px;padding: 10px;">
          <h4>Template SMS</h4>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mt-5">
            <vs-table stripe noDataText="aucune donnée" max-items="5" pagination search :data="[]">
              <template slot="header">
                <vs-button @click="isSidebarActiveLocal=true" v-if="selectUserOfficine(activeUserInfos.IdOfficine) && selectUserOfficine(activeUserInfos.IdOfficine).OfficinePrincipale === true" class="mb-4 mr-5 md:mb-0">Ajouter un Type de message</vs-button>
              </template>
              <template slot="thead">
                <vs-th>Objet</vs-th>
                <vs-th>message</vs-th>
                <vs-th>status</vs-th>
                <vs-th v-if="selectUserOfficine(activeUserInfos.IdOfficine) && selectUserOfficine(activeUserInfos.IdOfficine).OfficinePrincipale === true">Actions</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="tr.objet">
                    {{ tr.objet }}
                  </vs-td>
                  <vs-td>
                    {{ tr.content }}
                  </vs-td>
                  <vs-td>
                    {{ tr.auto }}
                  </vs-td>
                  <vs-td></vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
          <div class="vx-col w-1/2">
            <vs-textarea label="Anniversaire client" v-model="messageAnnivClientTemplate" />
          </div>
          <div class="vx-col w-1/2">
            <vs-textarea label="Anniversaire commande" v-model="messageAnnivCommandeTemplate" />
          </div>
          <div class="vx-col w-1/2">
            <vs-textarea class="mt-5" label="Livraison verre" v-model="messageClientLivraisonVerre" />
          </div>
          <div class="vx-col w-1/2">
            <vs-textarea class="mt-5" label="Montage terminer" v-model="messageClientMontageTerminer" />
          </div>
          <div class="vx-col w-1/2">
            <vs-textarea class="mt-5" label="Livraison equipement" v-model="messageClientLivraisonEquipement" />
          </div>
        </div>
        <vs-row class="p-5" vs-type="flex" vs-justify="flex-end">
          <vs-col vs-type="flex" vs-justify="end" vs-align="end">
            <vs-button @click="setSmsConfig">Sauvegarder</vs-button>
          </vs-col>
        </vs-row>
    </div>
</template>
<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
  name: 'templateSMS',
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      activeUserInfos: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
      IdUser: JSON.parse(localStorage.getItem('userInfo'))._id,
      id_sms_config: null,
      messageAnnivClientTemplate: null,
      messageAnnivCommandeTemplate: null,
      messageClientLivraisonVerre: null,
      messageClientMontageTerminer: null,
      messageClientLivraisonEquipement: null,
      messageClientProforma: null,

      type_name: null,
      content: null,
      objet: null,
      auto: false,
      emum: [
        { value: 'visiteur', text: 'Visiteur'},
        { value: 'anniv_client', text: 'Anniversaire client'},
        { value: 'new_client', text: 'Nouveau client'},
        { value: 'new_proforma', text: 'Nouvelle proforma'},
        { value: 'relance_proforma', text: 'Relance proforma'},
        { value: 'livraison_equipement', text: 'Livraison équipement'},
        { value: 'enquete_satisfaction', text: 'Enquête satisfaction'},
        { value: 'entretient_equipement', text: 'Entretien équipement'},
        { value: 'renouvellement_equipement', text: 'Renouvellement  équipement'},
        { value: 'reglement_caisse', text: 'Règlement caisse'},
        { value: 'reglemet_assurance', text: 'Règlement assurance'}
      ],
      isSidebarActiveLocal: false,
      settings: {
        maxScrollbarLength : 60,
        wheelSpeed         : .60
      }
    }
  },
  computed: {
    scrollbarTag () { return this.$store.state.is_touch_device ? 'div' : 'VuePerfectScrollbar' }
  },
  methods: {
    addTypeMessage () {
      const payload = {
        id_user: this.IdUser,
        content: this.content,
        objet: this.objet,
        auto: this.auto
      }
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('configs/createTypeSms', payload)
        .then((data) => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.alertCaisseId = data._id
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    setSmsConfig () {
      const payload = {
        id_user: this.IdUser,
        messageAnnivClientTemplate: this.messageAnnivClientTemplate,
        messageAnnivCommandeTemplate: this.messageAnnivCommandeTemplate,
        messageClientLivraisonVerre: this.messageClientLivraisonVerre,
        messageClientMontageTerminer: this.messageClientMontageTerminer,
        messageClientLivraisonEquipement: this.messageClientLivraisonEquipement,
        messageClientProforma: this.messageClientProforma
      }
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('configs/setSmsConfig', payload)
        .then((data) => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.alertCaisseId = data._id
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getSmsConfig () {
      this.$store.dispatch('configs/getSmsConfig')
        .then((response) => {
          this.id_sms_config = response.data.smsConfig ? response.data.smsConfig._id : null
          this.messageAnnivClientTemplate = response.data.smsConfig ? response.data.smsConfig.messageAnnivClientTemplate : null
          this.messageAnnivCommandeTemplate = response.data.smsConfig ? response.data.smsConfig.messageAnnivCommandeTemplate : null
          this.messageClientLivraisonVerre = response.data.smsConfig ? response.data.smsConfig.messageClientLivraisonVerre : null
          this.messageClientMontageTerminer = response.data.smsConfig ? response.data.smsConfig.messageClientMontageTerminer : null
          this.messageClientLivraisonEquipement = response.data.smsConfig ? response.data.smsConfig.messageClientLivraisonEquipement : null
          this.messageClientProforma = response.data.smsConfig ? response.data.smsConfig.messageClientProforma : null
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },

    selectUserOfficine (id) {
      return this.$store.getters['officine/getOfficineById'](id)
    }
  },
  created () {
    this.getSmsConfig()
  }
}
</script>
<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 50vw;
    max-width: 50vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style>