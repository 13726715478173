<template>
    <div class="vx-col w-full mt-5">
        <div class="h-12" style="background-color: #dae1e7;border-radius: 5px;padding: 10px;">
          <h4>Politique de garantie</h4>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2">
            <vs-input name="garantieMontureDefaut" class="w-full" label="Garantie Monture" v-model="garantieMontureDefaut"></vs-input>
          </div>
          <div class="vx-col w-1/2">
            <vs-input name="garantieVerreDefaut" class="w-full" label="Garantie Verre" v-model="garantieVerreDefaut"></vs-input>
          </div>
        </div>
        <div class="vx-row p-5">
            <div class="flex space-between">
              <div class="flex mr-5">
                <h5>
                    Avancée
                </h5>
                <vx-tooltip
                  color="black"
                  title="Politique de garantie"
                  text="Cette politique efface la garantie par défaut"
                >
                  <feather-icon class="ml-1" icon="InfoIcon" />
                </vx-tooltip>
              </div>

              <vs-switch label="Avancée" v-model="advancedGarantie">
                <span slot="on">Oui</span>
                <span slot="off">Non</span>
              </vs-switch>
            </div>
            <div class="flex justify-between w-full mt-5" v-if="advancedGarantie">
                <div class="w-1/2 mt-5 mr-5">
                    <h6>Monture</h6>
                    <vs-table class="mt-2" noDataText="aucune garantie" stripe :data="garantiesMontue">
                        <!-- HEADER -->
                        <template slot="thead">
                            <vs-th class="pointer-events-none">Prix min</vs-th>
                            <vs-th class="pointer-events-none">Prix max</vs-th>
                            <vs-th class="pointer-events-none">Garantie</vs-th>
                            <vs-th class="pointer-events-none"></vs-th>
                        </template>
                        <!-- DATA -->
                        <template  class="mb-5">
                        <vs-tr v-for="(item, index) in garantiesMontue" :key="index">
                            <vs-td>
                                <vs-input type="number" min="0" class="w-full" v-model="item.prixMin"></vs-input>
                            </vs-td>
                            <vs-td>
                                <vs-input type="number" min="0" class="w-full" v-model="item.prixMax"></vs-input>
                            </vs-td>
                            <vs-td>
                                <vs-input type="text" class="w-full" v-model="item.garantie"></vs-input>
                            </vs-td>
                            <vs-td>
                                <vs-button color="danger" type="filled" icon-pack="feather" icon="icon-trash" @click="removeLineGarantieMonture(index)"></vs-button>
                            </vs-td>
                        </vs-tr>
                        </template>
                    </vs-table>
                    <div class="mt-4">
                        <vs-button color="primary" type="filled" @click="addNewLineGarantieMonture">Ajouter une garantie monture</vs-button>
                    </div>
                </div>
                <div class="w-1/2 mt-5">
                    <h6>Verres</h6>
                    <vs-table class="mt-2" noDataText="aucune garantie" stripe :data="garantiesVerre">
                        <!-- HEADER -->
                        <template slot="thead">
                            <vs-th class="pointer-events-none">Prix min</vs-th>
                            <vs-th class="pointer-events-none">Prix max</vs-th>
                            <vs-th class="pointer-events-none">Garantie</vs-th>
                            <vs-th class="pointer-events-none"></vs-th>
                        </template>
                        <!-- DATA -->
                        <template  class="mb-5">
                        <vs-tr v-for="(item, index) in garantiesVerre" :key="index">
                            <vs-td>
                                <vs-input type="number" min="0" class="w-full" v-model="item.prixMin"></vs-input>
                            </vs-td>
                            <vs-td>
                                <vs-input type="number" min="0" class="w-full" v-model="item.prixMax"></vs-input>
                            </vs-td>
                            <vs-td>
                                <vs-input type="text" class="w-full" v-model="item.garantie"></vs-input>
                            </vs-td>
                            <vs-td>
                                <vs-button color="danger" type="filled" icon-pack="feather" icon="icon-trash" @click="removeLineGarantieVerre(index)"></vs-button>
                            </vs-td>
                        </vs-tr>
                        </template>
                    </vs-table>
                    <div class="mt-4">
                        <vs-button color="primary" type="filled" @click="addNewLineGarantieVerres">Ajouter une garantie verre</vs-button>
                    </div>
                </div>
            </div>
        </div>
        <vs-row class="p-5" vs-type="flex" vs-justify="flex-end">
          <vs-col vs-type="flex" vs-justify="end" vs-align="end">
            <vs-button @click="setGarantie">Sauvegarder</vs-button>
          </vs-col>
        </vs-row>
    </div>
</template>
<script>
export default {
  name: 'Garantie',
  data () {
    return {
      IdUser: JSON.parse(localStorage.getItem('userInfo'))._id,
      id_garantie: null,
      advancedGarantie: false,
      garantieMontureDefaut: null,
      garantieVerreDefaut: null,
      garantiesMontue: [],
      garantiesVerre: [],
      officines: []
    }
  },
  methods: {
    addNewLineGarantieMonture () {
      this.garantiesMontue.push({
        prixMin: 0,
        prixMax: 0,
        garantie: ''
      })
    },
    removeLineGarantieMonture (index) {
      this.garantiesMontue.splice(index, 1)
    },
    addNewLineGarantieVerres () {
      this.garantiesVerre.push({
        prixMin: 0,
        prixMax: 0,
        garantie: ''
      })
    },
    removeLineGarantieVerre (index) {
      this.garantiesVerre.splice(index, 1)
    },
    setGarantie () {
      const payload = {
        IdUser: this.IdUser,
        officines: this.officines,
        advancedGarantie: this.advancedGarantie,
        garantieMontureDefaut: this.garantieMontureDefaut,
        garantieVerreDefaut: this.garantieVerreDefaut,
        garantiesMontue: this.garantiesMontue,
        garantiesVerre: this.garantiesVerre
      }
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('configs/setGarantie', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.$vs.loading.close()
        })
        .catch(err => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getGarantie () {
      this.$store.dispatch('configs/getGarantie')
        .then((response) => {
          this.id_garantie = response.data.garantie ? response.data.garantie._id : null
          this.officines = response.data.garantie ? response.data.garantie.officines : []
          this.advancedGarantie = response.data.garantie ? response.data.garantie.advancedGarantie : false
          this.garantieMontureDefaut = response.data.garantie ? response.data.garantie.garantieMontureDefaut : null
          this.garantieVerreDefaut = response.data.garantie ? response.data.garantie.garantieVerreDefaut : null
          this.garantiesMontue = response.data.garantie ? response.data.garantie.garantiesMontue : []
          this.garantiesVerre = response.data.garantie ? response.data.garantie.garantiesVerre : []
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    }
  },
  created () {
    this.getGarantie()
  }
}
</script>